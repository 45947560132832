// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import tipografie from "assets/images/tipografie.jpg";
import iso from "assets/images/iso-venus.jpg";

function Information() {
  return (
    <MKBox id="aboutUs" component="section" pt={{ lg: 16 }}>
      <Container>
        <Grid container spacing={3} mb={10} alignItems="top">
          <Grid item xs={12} lg={8}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mt={4}>
                  <DefaultInfoCard
                    icon="public"
                    title="Cine am fost?"
                    description="Înființată in 1994, sub denumirea de Casa de Editură Venus SRL, tipografia a cunoscut o dezvoltare
                    continua de la înființare și pănâ în prezent. <br /><br />Dezvoltarea tipografiei a constat in: <br />
                    &#8226; achiziția de utilaje tipografice adaptate cerințelor tehnologice ale perioadei noastre <br />
                    &#8226; perfecționarea continuă a personalului <br />
                    &#8226; constituirea unui portofoliu solid de clienți"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={tipografie}
              title="Tipografia Venus"
              description="Cu o echipă completă de profesioniști la dispoziția ta, orice este posibil!"
              action={{
                type: "internal",
                route: "/contact",
                color: "warning",
                label: "CERE OFERTA",
              }}
            />
          </Grid>
        </Grid>
        <Grid style={{ borderTop: "1px solid #e6e6e6" }} container spacing={3} alignItems="top">
          <Grid item xs={12} lg={12} mt={4}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="diversity_3"
                    title="Cine suntem?"
                    description="În anul 2012, tipografia noastră a cunoscut un proces de rebranding. Noua denumire a ei este VENUS PRINTING SOLUTIONS SRL. <br /><br /> În dorința de a asigura clienților noștri servicii de calitate certificate, am reusit obtinerea certificarii ISO 9001:2008 în managementul calității. <br /> "
                    imageEx={iso}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="model_training"
                    title="Ce dorim să fim?"
                    description="În viitorul mediu și apropiat ne dorim să fim în continuare una din tipografiile din Iași recunoscute pentru calitatea serviciilor tipografice prestate. <br /> <br /> În acest sens, avem un plan de investiții in următorii ani care va avea ca scop:  <br /> 
                    &#8226; extinderea capacității de producție <br /> 
                    &#8226; creșterea continuă a calității serviciilor tipografice <br /> 
                    &#8226; scurtarea timpilor de execuție"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
