// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Images
import roton from "assets/images/logos/gray-logos/logo-roton.png";
import afer from "assets/images/logos/gray-logos/logo-afer.png";
import knox from "assets/images/logos/gray-logos/logo-knox.png";
import nutrifitup from "assets/images/logos/gray-logos/logo-nutrifitup.png";
import gemini from "assets/images/logos/gray-logos/logo-gemini.png";
import delivery from "assets/images/logos/gray-logos/logo-delivery.png";

function Featuring() {
  return (
    <MKBox id="clients" component="section" pt={12}>
      <Container>
        <Grid container justifyContent="center" mb={10} sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={20}
              separator=","
              suffix="+"
              title="Utilaje"
              description="Peste 20 utilaje profesionale care livreaza o calitate superioara a produselor."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={500}
              suffix="~"
              title="Clienti"
              description="Avem o baza mare de clienti pentru care am realizat produse."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              prefix=">"
              count={104386}
              separator=","
              title="Comenzi"
              description="Datorita calitatii produselor livrate am mentinut o activitate constanta."
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ mb: 4, pb: 12 }}
          style={{ borderBottom: "1px solid #e6e6e6" }}
        >
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={roton} alt="roton" width="100%" opacity={1} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={afer} alt="afer" width="100%" opacity={1} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={knox} alt="knox" width="100%" opacity={1} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={nutrifitup} alt="nutrifitup" width="100%" opacity={1} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={delivery} alt="delivery" width="100%" opacity={1} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={gemini} alt="gemini" width="100%" opacity={1} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
