// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
//import macbook from "assets/images/macbook.png";
import "./Newsletter.css";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";

function Newsletter() {
  const form = useRef();
  const [formValues, setFormValues] = useState({});
  const [email, setEmail] = useState("");
  const [firstName, setName] = useState("");
  const [message, setMessage] = useState("");
  const [lastName, setLastName] = useState("");
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);
    // functia de submit
    emailjs.sendForm("service_rzpdabi", "template_u8wp0cj", form.current, "Hb7UF_fJQM-ZwR9xY").then(
      (result) => {
        console.log(result);
        setShow(!show);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <MKBox id="contact" component="section" pb={12}>
      <MKBox component="section" py={6}>
        <Container>
          <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={show} timeout={500}>
              <MKBox
                position="relative"
                width="100%"
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
                style={{ maxWidth: 500 }}
              >
                <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h3" display="flex" alginItems="center">
                    SUCCES &nbsp;
                    <Icon pl={2} color="success">
                      check_circle
                    </Icon>
                  </MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                  <MKTypography variant="body2" color="secondary" fontWeight="regular">
                    Mesajul tau a fost trimis cu succes! <br /> Iti multumim pentru timpul acordat
                    si in cel mai scurt timp posibil vei fi contactat de catre noi.
                  </MKTypography>
                </MKBox>
                <Divider sx={{ my: 0 }} />
              </MKBox>
            </Slide>
          </Modal>
        </Container>
      </MKBox>
      <Container>
        <Grid container alignItems="center" justifyContent="center">
          <MKTypography variant="h1" color="dark" style={{ width: "100%", textAlign: "center" }}>
            CONTACT
          </MKTypography>
          <MKTypography
            variant="h4"
            color="dark"
            pb={6}
            xl={{ px: 14 }}
            style={{ textAlign: "center" }}
          >
            Completeaza formularul de mai jos sau foloseste datele de contact
            <br className="mobile-hide" /> pentru a intra in legatura cu echipa noastra.
          </MKTypography>
          <Grid item lg={6} mt={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <Grid item xs={12} ml={{ xs: "auto", lg: 6 }} mr={{ xs: "auto", lg: 6 }}>
              <MKBox
                bgColor="white"
                borderRadius="xl"
                shadow="lg"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mx={{ xs: "auto", xl: 3 }}
              >
                <MKBox
                  variant="gradient"
                  bgColor="warning"
                  coloredShadow="warning"
                  borderRadius="lg"
                  p={2}
                  mx={2}
                  mt={-3}
                >
                  <MKTypography variant="h3" color="white">
                    Ai intrebari?
                  </MKTypography>
                </MKBox>
                <MKBox p={3}>
                  <MKTypography variant="body2" color="text" mb={3}>
                    Transmite-ne intrebarile tale, solicitarile sau parerea ta. Suntem aici sa te
                    ajutam si sa raspundem la nevoile tale.
                  </MKTypography>
                  <MKBox
                    width="100%"
                    component="form"
                    method="post"
                    autoComplete="off"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          variant="standard"
                          label="Nume *"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            required: "required",
                            name: "user_name",
                            value: firstName,
                            onInput: (e) => {
                              setName(e.target.value);
                              setFormValues({
                                ...formValues,
                                [e.target.name]: e.target.value,
                              });
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          variant="standard"
                          label="Nume Companie"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            name: "user_company",
                            value: lastName,
                            onInput: (e) => {
                              setLastName(e.target.value);
                              setFormValues({
                                ...formValues,
                                [e.target.name]: e.target.value,
                              });
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          type="email"
                          variant="standard"
                          label="Email *"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            required: "required",
                            name: "user_email",
                            value: email,
                            onInput: (e) => {
                              setEmail(e.target.value);
                              setFormValues({
                                ...formValues,
                                [e.target.name]: e.target.value,
                              });
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          variant="standard"
                          label="Cu ce te putem ajuta? *"
                          placeholder="..."
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            minlength: 30,
                            maxlength: 3000,
                            required: "required",
                            name: "message",
                            value: message,
                            onInput: (e) => {
                              setMessage(e.target.value);
                              setFormValues({
                                ...formValues,
                                [e.target.name]: e.target.value,
                              });
                            },
                          }}
                          multiline
                          fullWidth
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                      <MKButton type="submit" variant="gradient" color="warning">
                        Trimite
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ mt: 5 }}>
            <MKBox position="relative">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2712.755812161415!2d27.575425176713924!3d47.1626367185294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafb70e7fdf257%3A0x53a5f2fa12abe8a0!2sVenus%20Printing%20Printing%20Solutions!5e0!3m2!1sen!2sro!4v1695564836336!5m2!1sen!2sro"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </MKBox>
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                item
                xs={12}
                lg={5}
                sx={{ mt: 5 }}
                style={{ display: "flex" }}
                alignItems="center"
                justifyContent="center"
              >
                <Icon fontSize="large">phone</Icon>
                <MKTypography
                  component="a"
                  href="tel:0722646793"
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                >
                  0722 646 793
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={7}
                sx={{ mt: 5 }}
                style={{ display: "flex" }}
                alignItems="center"
                justifyContent="center"
              >
                <Icon fontSize="large">email</Icon>
                <MKTypography
                  component="a"
                  href="mailto:tipografia@venus.ro"
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                >
                  tipografia@venus.ro
                </MKTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
