// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
//import logoCT from "assets/images/logo-ct-dark.png";
import logo from "assets/images/logos/venus_logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "VENUS PRINTING SOLUTIONS",
    image: logo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      name: "Facebook",
      link: "https://www.facebook.com/venusprintingsolution/",
    },
  ],
  menus: [
    {
      name: "Tipografie",
      items: [
        { name: "Despre Noi", route: "/aboutUs" },
        { name: "Produse", route: "/products" },
        { name: "Clienti", route: "/clients" },
        { name: "Servicii", route: "/Services" },
      ],
    },
    {
      name: "Informatii",
      items: [
        { name: "Cere Oferta", route: "/contact" },
        { name: "Informatii Utile", route: "/informations" },
      ],
    },
    {
      name: "Suport",
      items: [
        { name: "Termeni & conditii", route: "/terms" },
        { name: "Politica Confidentialitate", route: "/terms" },
        { name: "Contact", route: "/contact" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright {date} &copy; S.C. VENUS PRINTING SOLUTIONS S.R.L.
    </MKTypography>
  ),
};
