// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
// Images
import bgImage from "assets/images/bg-products.jpg";
import cutii from "assets/images/cutii/cutii.png";
import cutii1 from "assets/images/cutii/cutii1.png";
import cutii2 from "assets/images/cutii/cutii2.png";
import etichete from "assets/images/etichete/Etichete.png";
import etichete1 from "assets/images/etichete/etichete1.png";
import fluturasi from "assets/images/fluturasi/fluturasi.png";
import foicuantet from "assets/images/foicuantet/foicuantet.png";
import mapa from "assets/images/mape/mapa.png";
import brosura from "assets/images/brosuri/Brosura.png";
import calendar1 from "assets/images/calendare/calendar1.png";
import calendar2 from "assets/images/calendare/calendar2.png";
import calendar3 from "assets/images/calendare/calendar3.png";
import carti from "assets/images/carti/carti.png";
import carti1 from "assets/images/carti/carti1.png";
import carti2 from "assets/images/carti/carti2.png";
import afis from "assets/images/afise/afis.png";
import alteproduse from "assets/images/alteproduse/alteproduse.png";
import alteproduse1 from "assets/images/alteproduse/alteproduse1.png";
import alteproduse2 from "assets/images/alteproduse/alteproduse2.png";
import reviste1 from "assets/images/reviste/reviste1.png";
import reviste2 from "assets/images/reviste/reviste2.png";
import reviste3 from "assets/images/reviste/reviste3.png";
import reviste4 from "assets/images/reviste/reviste4.png";
import ambalaje1 from "assets/images/ambalaje/ambalaje1.png";
import cataloage1 from "assets/images/cataloage/cataloage1.png";
import cataloage2 from "assets/images/cataloage/cataloage2.png";
import tipizate from "assets/images/tipizate/tipizate.png";
import ziare1 from "assets/images/ziare/ziare1.png";
// import cutie4 from "assets/images/cutii/cutie4.jpg";
// import cutie5 from "assets/images/cutii/cutie5.jpg";
// import cutie6 from "assets/images/cutii/cutie6.jpg";
import "./products.css";
import { useState } from "react";

function Products() {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  let [title, setTitle] = useState("");
  let [items, setItems] = useState([]);

  const allProducts = {
    Cutii: [cutii, cutii1, cutii2],
    Etichete: [etichete, etichete1],
    Fluturasi: [fluturasi],
    FoiCuAntet: [foicuantet],
    Mape: [mapa],
    Brosuri: [brosura],
    Calendare: [calendar1, calendar2, calendar3],
    Carti: [carti, carti1, carti2],
    Afise: [afis],
    AlteProduse: [alteproduse, alteproduse1, alteproduse2],
    Reviste: [reviste1, reviste2, reviste3, reviste4],
    Ambalaje: [ambalaje1],
    Cataloage: [cataloage1, cataloage2],
    Tipizate: [tipizate],
    Ziare: [ziare1],
  };

  const setModal = (product, images) => {
    setTitle(product);
    setActiveIndex(0);
    setItems(allProducts[images]);
    setShow(!show);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = () => {
    setActiveIndex((activeIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setActiveIndex((activeIndex - 1 + items.length) % items.length);
  };

  return (
    <MKBox id="products" component="section" pt={{ xs: 0, sm: 12 }}>
      <MKBox component="section">
        <Container>
          <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
            <MKBox
              position="relative"
              width="100%"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
              style={{ maxWidth: 700, outline: 0 }}
            >
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h3" display="flex" alginItems="center">
                  {title} &nbsp;
                </MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <div className="carousel">
                  <div className="carousel-inner">
                    {items.map((item, index) => (
                      <div
                        key={index}
                        className={`carousel-item ${index === activeIndex ? "active" : ""}`}
                      >
                        <img src={item} alt={`Slide ${index + 1}`} style={{ width: "100%" }} />
                      </div>
                    ))}
                  </div>
                  <a className="carousel-control-prev" onClick={prevSlide}>
                    <Icon sx={{ mr: 2, fontWeight: "bold" }}>chevron_left</Icon>
                  </a>
                  <a className="carousel-control-next" onClick={nextSlide}>
                    <Icon sx={{ mr: 2, fontWeight: "bold" }}>chevron_right</Icon>
                  </a>
                </div>
              </MKBox>
              <Divider sx={{ my: 0 }} />
            </MKBox>
          </Modal>
        </Container>
      </MKBox>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        <MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "auto", lg: "100%" }}
          height={{ xs: "100%", lg: "auto" }}
          zIndex={1}
          opacity={0.2}
        />
        <Container sx={{ position: "relative", zIndex: 2, py: 12 }}>
          <Grid container item xs={12} md={12} justifyContent="center" mx="auto" textAlign="center">
            <MKBox style={{ width: "100%" }}>
              <MKTypography variant="h1" color="white" mb={2}>
                PRODUSE
              </MKTypography>
              <MKTypography variant="h4" color="white" mb={6}>
                Oferim o gamă variată de produse personalizate:
              </MKTypography>
              <Grid container spacing={3} mb={5} alignItems="top">
                <Grid item xs={12} lg={4}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={12} className="produs-item" display="grid">
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        onClick={() => setModal("Carti", "Carti")}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Cărți
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Pliante
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        onClick={() => setModal("Afise", "Afise")}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Afise
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        onClick={() => setModal("Fluturasi", "Fluturasi")}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Fluturasi
                      </MKTypography>

                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        onClick={() => setModal("Brosuri", "Brosuri")}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Brosuri
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        onClick={() => setModal("Cataloage", "Cataloage")}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Cataloage
                      </MKTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={12} md={12} className="produs-item" display="grid">
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        onClick={() => setModal("Reviste", "Reviste")}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Reviste
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        onClick={() => setModal("Cutii", "Cutii")}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Cutii
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        onClick={() => setModal("Ambalaje", "Ambalaje")}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Ambalaje
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        onClick={() => setModal("Etichete", "Etichete")}
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Etichete
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Naproane
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="h5v"
                        color="white"
                        fontWeight="regular"
                        sx={{
                          width: "max-content",
                          display: "flex",
                          alignItems: "center",

                          "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "scale(1, 1)",
                            transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                          },
                          "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "scale(1.4, 1.4)",
                          },
                          "&:hover, &:focus": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                        Agende
                      </MKTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={12} md={12} className="produs-item" display="grid">
                      <MKTypography
                        variant="h5v"
                        color="white"
                        mb={6}
                        style={{ textAlign: "left", maxWidth: "270px" }}
                      >
                        <MKTypography
                          component="a"
                          variant="h5v"
                          color="white"
                          fontWeight="regular"
                          onClick={() => setModal("Calendare", "Calendare")}
                          style={{ textAlign: "left", maxWidth: "270px" }}
                          sx={{
                            width: "max-content",
                            display: "flex",
                            alignItems: "center",

                            "& .material-icons-round": {
                              fontSize: "1.125rem",
                              transform: "scale(1, 1)",
                              transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                            },
                            "&:hover .material-icons-round, &:focus .material-icons-round": {
                              transform: "scale(1.4, 1.4)",
                            },
                            "&:hover, &:focus": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                          Calendare
                        </MKTypography>
                        <MKTypography
                          component="a"
                          variant="h5v"
                          color="white"
                          fontWeight="regular"
                          onClick={() => setModal("Mape", "Mape")}
                          style={{ textAlign: "left", maxWidth: "270px" }}
                          sx={{
                            width: "max-content",
                            display: "flex",
                            alignItems: "center",

                            "& .material-icons-round": {
                              fontSize: "1.125rem",
                              transform: "scale(1, 1)",
                              transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                            },
                            "&:hover .material-icons-round, &:focus .material-icons-round": {
                              transform: "scale(1.4, 1.4)",
                            },
                            "&:hover, &:focus": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                          Mape
                        </MKTypography>
                        <MKTypography
                          component="a"
                          variant="h5v"
                          color="white"
                          fontWeight="regular"
                          onClick={() => setModal("Foi cu antet", "FoiCuAntet")}
                          style={{ textAlign: "left", maxWidth: "270px" }}
                          sx={{
                            width: "max-content",
                            display: "flex",
                            alignItems: "center",

                            "& .material-icons-round": {
                              fontSize: "1.125rem",
                              transform: "scale(1, 1)",
                              transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                            },
                            "&:hover .material-icons-round, &:focus .material-icons-round": {
                              transform: "scale(1.4, 1.4)",
                            },
                            "&:hover, &:focus": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                          Foi cu antet
                        </MKTypography>
                        <MKTypography
                          component="a"
                          variant="h5v"
                          color="white"
                          fontWeight="regular"
                          onClick={() => setModal("Tipizate", "Tipizate")}
                          sx={{
                            width: "max-content",
                            display: "flex",
                            alignItems: "center",

                            "& .material-icons-round": {
                              fontSize: "1.125rem",
                              transform: "scale(1, 1)",
                              transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                            },
                            "&:hover .material-icons-round, &:focus .material-icons-round": {
                              transform: "scale(1.4, 1.4)",
                            },
                            "&:hover, &:focus": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                          Tipizate
                        </MKTypography>
                        <MKTypography
                          component="a"
                          variant="h5v"
                          color="white"
                          fontWeight="regular"
                          onClick={() => setModal("Ziare", "Ziare")}
                          sx={{
                            width: "max-content",
                            display: "flex",
                            alignItems: "center",

                            "& .material-icons-round": {
                              fontSize: "1.125rem",
                              transform: "scale(1, 1)",
                              transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                            },
                            "&:hover .material-icons-round, &:focus .material-icons-round": {
                              transform: "scale(1.4, 1.4)",
                            },
                            "&:hover, &:focus": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                          Ziare
                        </MKTypography>
                        <MKTypography
                          component="a"
                          variant="h5v"
                          color="white"
                          fontWeight="regular"
                          onClick={() => setModal("Alte produse", "AlteProduse")}
                          sx={{
                            width: "max-content",
                            display: "flex",
                            alignItems: "center",

                            "& .material-icons-round": {
                              fontSize: "1.125rem",
                              transform: "scale(1, 1)",
                              transition: "all 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                            },
                            "&:hover .material-icons-round, &:focus .material-icons-round": {
                              transform: "scale(1.4, 1.4)",
                            },
                            "&:hover, &:focus": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Icon sx={{ mr: 2, fontWeight: "bold" }}>zoom_out_map</Icon>
                          Alte produse
                        </MKTypography>
                      </MKTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <MKButton
                type="internal"
                variant="gradient"
                color="warning"
                size="large"
                component={Link}
                to="/contact"
                sx={{ mb: 2 }}
              >
                CERE OFERTA
              </MKButton>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Products;
