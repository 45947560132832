/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpg";
import bgBack from "assets/images/rotating-card-bg-back.jpg";

function Utils() {
  return (
    <MKBox id="Informations" component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgBack}
                color="warning"
                icon="touch_app"
                title={
                  <>
                    Informatii
                    <br />
                    Utile
                  </>
                }
                description="Pentru a putea realiza comenzile pe care le primim uneori avem nevoie de informatii suplimentare."
              />
              <RotatingCardBack
                image={bgFront}
                color="warning"
                title="Recomandari"
                description="In acelasi timp, tipografia lucreaza cu anumite standarde de la care nu ne abatem."
                action={{
                  type: "internal",
                  route: "/contact",
                  label: "Cere oferta",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Pre-Press"
                  description="Recomandam ca fisierele sa fie trimise in format PDF  sau CDR (cu fonturile transformate in curbe), iar ca sistem de culori recomandam CMYK."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="format_color_fill"
                  title="Lacuire UV selectiva"
                  description="Pentru o calitate superioara a produsului final, recomandam ca suportul de tipar sa fie plastifiat mat inainte de lacuirea UV selectiva."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="label"
                  title="Stantare"
                  description="Pentru asigurarea calitatii operatiei de stantare, stantele se executa numai la furnizori agreati.
                  Stanta se factureaza separat, fiind achitata o singura data de catre client, cu ocazia primei comenzi."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="local_shipping"
                  title="Transport"
                  description="Transportul produselor finite la client este gratuit pe teritoriul municipiului Iasi cu mijloace proprii, iar in Romania prin intermediul firmelor de curierat, este gratuit la comenzi de peste 500 lei."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="print"
                  title="Tipar"
                  description="Formatul maxim de tipar este 65x45cm, iar cel minim este de 25x35cm."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Utils;
