// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import prepress from "assets/images/prepress.jpg";
import locuireOffset from "assets/images/locuire-offset.jpg";
import embosarea from "assets/images/embosarea.jpg";
import faltuire from "assets/images/faltuire.jpg";
import brosare from "assets/images/brosare.jpg";
import arc from "assets/images/arc.jpg";
import capsate from "assets/images/capsate.jpg";
import ambalare from "assets/images/ambalare.jpg";
import stantare from "assets/images/stantare.jpg";
import laminare from "assets/images/laminare.jpg";
import tiparire from "assets/images/tiparire.jpg";
import taiere from "assets/images/taiere.jpg";

function Team() {
  return (
    <MKBox
      id="Services"
      component="section"
      variant="gradient"
      position="relative"
      pt={{ xs: 2, lg: 12 }}
      pb={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="dark">
              Servicii
            </MKTypography>
            <MKTypography variant="body2" color="dark" opacity={0.8}>
              Tipografia Venus Printing Solutions oferă o diversitate de servicii de tipar offset,
              cuprinzând și finisarea ulterioară, pentru a vă asigura produse finite de calitate,
              esențiale în procesul de vânzare și promovare a produselor dumneavoastră.
            </MKTypography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={prepress}
                name="Pre-Press"
                position={{ color: "", label: "" }}
                description="Este procesul de pregătire pentru tipar. Fișierele dumneavostră se prelucrează pentru a putea începe procesul de tipărire - se aleg culorile speciale și se fac eventualele corecturi necesare unei lucrări de calitate."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={tiparire}
                name="Tipărire"
                position={{ color: "", label: "" }}
                description="Acest proces constă în imprimarea propriu-zisă, prin intermediul unor matrițe speciale, pe suprafețele de baza - hârtie, carton simplu sau carton special, autocolant sau hârtie autocopiativă."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={locuireOffset}
                name="Lăcuire Offset"
                position={{ color: "", label: "" }}
                description="Este procesul prin care, peste materialul tipărit, se depune un strat fin de lac, secvențial sau pe întreaga suprafață, pentru a mari rezistența la manipulare sau pentru a evidenția detaliile importante."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={laminare}
                name="Laminare"
                position={{ color: "", label: "" }}
                description="Este lipirea cu ajutorul temperaturii ridicate, a unei folii speciale, peste zona tipărită, pentru a-i da substratului rezistență la căldură și o estetică aparte. Laminarea poate fi lucioasă, mată sau soft touch."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={embosarea}
                name="Embossarea"
                position={{ color: "", label: "" }}
                description="Folosită în mod special pentru cutii, mape și coperți de carte, embossarea este procedeul de scoatere în relief sau presare în orb, a cartonului, pentru a îi conferi o senzație tactilă deosebită."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={faltuire}
                name="Fălțuire"
                position={{ color: "", label: "" }}
                description="Este modalitatea prin care materialul tipărit se pliază pentru a putea primi forma finală. Este folosită cu precădere pentru tipărituri ce devin pliante, dar și pentru cele ce urmează a fi broșate sau legate."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={brosare}
                name="Broșare"
                position={{ color: "", label: "" }}
                description="Broșarea este tehnica prin care se finalizează ca formă cărțile și cataloagele, prin depunerea unui strat de termoclei în zona de cotor, lipindu-se împreună interiorul și coperta."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={capsate}
                name="Capsare"
                position={{ color: "", label: "" }}
                description="Precum operațiunea de broșar, capsarea este un procedeu care se adresează finalizării lucrărilor, doar că se utilizează pentru prindere capsele metalice pentru rapiditatea și siguranța lor. "
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={arc}
                name="Legătorie"
                position={{ color: "", label: "" }}
                description="Materialul tipărit este asamblat și consolidat prin diverse etape care conferă în final o estetică aparte produsului. Tehnica de legare cu arc a câștigat popularitate datorită structurii sale ușor de manevrat."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={stantare}
                name="Ștanțare"
                position={{ color: "", label: "" }}
                description="După tipărire și laminare/lăcuire cutiile, mapele sau etichetele intră în procesul de decupare automatizată pentru a primi forma specifică. Ștanțarea poate fi îmbinată în această formă finală și cu embossarea."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={taiere}
                name="Taierea"
                position={{ color: "", label: "" }}
                description="Este etapa esențială în producția de materiale  tipărite de la cărți și broșuri, până la flyere și etichete permițând obținerea dimensiunilor finale stabilite în proiectul inițial."
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={ambalare}
                name="Ambalare"
                position={{ color: "", label: "" }}
                description="Produsele tipărite trebuie să fie ambalate astfel încât să reziste manipulării și transportului, reducând riscul de deteriorare în tranzit."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
