import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
//import Presentation from "layouts/pages/presentation";
import AboutUs from "layouts/pages/landing-pages/about-us";
import Services from "layouts/pages/services";
import Terms from "layouts/pages/terms";
import Informations from "layouts/pages/informations";
// Material Kit 2 React routes
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    var anchor = pathname.split("/")[1];
    setTimeout(() => {
      if (anchor == "products") {
        document.getElementById("products").scrollIntoView({ behavior: "smooth" });
      } else if (anchor == "aboutUs") {
        document.getElementById("aboutUs").scrollIntoView({ behavior: "smooth" });
      } else if (anchor == "clients") {
        document.getElementById("clients").scrollIntoView({ behavior: "smooth" });
      } else if (anchor == "Services") {
        document.getElementById("Services").scrollIntoView({ behavior: "smooth" });
      } else if (anchor == "contact") {
        document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
      } else if (anchor == "informations") {
        document.getElementById("Informations").scrollIntoView({ behavior: "smooth" });
      } else {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
      }
    }, 300);
  });

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<AboutUs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/informations" element={<Informations />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
